<template>
  <FieldArray class="form-array" :name="fieldName" v-slot="{ fields, push, remove }">
    <div class="form-item" v-for="(field, index) in fields" :key="field.key">
      <DatePicker
        v-model="field.value.entryExitDate"
        :masks="masks"
        :popover="popover"
        :attributes="[
          ...attrs,
          {
            highlight: true,
            dates: fields.map((field) => field.value.showDate),
          },
        ]"
        :model-config="modelConfig"
        :min-date="minDate"
        :max-date="maxDate"
        :disabled-dates="[...totalDisabledDates, ...fields.map((field) => field.value.showDate)]"
        @update:to-page="pageChange"
      >
        <template v-slot="{ inputValue: dateVal, inputEvents: dateEvent }">
          <div class="grid-row">
            <div class="col-6">
              <label class="form-label">{{formLabel}}{{ index + 1 }}</label>
              <Field
                v-model="field.value.entryExitDate"
                :name="`${fieldName}[${index}].entryExitDate`"
                v-slot="{ errors }"
              >
                <input
                  type="text"
                  class="form-input"
                  :class="{ 'is-invalid': errors.length > 0 }"
                  :value="dateVal"
                  v-on="dateEvent"
                />
              </Field>
              <div class="form-promp is-invalid">
                <ErrorMessage :name="`${fieldName}[${index}].entryExitDate`" />
              </div>
            </div>
            <div class="col-6">
              <label class="form-label" style="display: flex; align-items: center;">
                時段 <span class="form-promp is-notify d-none-mobile">(按住Ctrl可進行多選)</span>
              </label>
              <div class="form-group">
                <div style="flex: 1">
                  <Field
                    class="form-input"
                    v-slot="{ value }"
                    :name="`${fieldName}[${index}].timeDuration`"
                    as="select"
                    multiple
                  >
                  <template v-if="dateVal && timeOptions[dateVal]">
                    <option
                      v-for="timeOption in timeOptions[dateVal]"
                      :key="timeOption.pmId"
                      :value="timeOption.pmId"
                      :selected="value && value.includes(timeOption.pmId)"
                    >
                      {{ timeOption.pmText }}
                    </option>
                  </template>
                  <template v-else>
                    <option value="" disabled>請先選擇日期</option>
                  </template>
                  </Field>
                  <div class="form-promp is-invalid">
                    <ErrorMessage :name="`${fieldName}[${index}].timeDuration`" />
                  </div>
                </div>
                <button
                  type="button"
                  class="btn btn-secondary form-input-btn"
                  @click="remove(index)"
                >
                  <i class="icon icon-close" aria-hidden="true"></i>
                </button>
              </div>
              <div class="form-promp is-invalid">
                <ErrorMessage :name="`${fieldName}[${index}].sessions`" />
              </div>
            </div>
          </div>
        </template>
      </DatePicker>
    </div>
    <button type="button" class="btn btn-primary" @click="addCallback(push)" :disabled="!isRangeSetted">
      <i class="icon icon-plus" aria-hidden="true"></i> 新增{{formLabel}}
    </button>
    <span class="form-promp is-invalid" :id="fieldName" v-if="isRangeSetted && fields.length === 0">請填寫至少1日</span>
  </FieldArray>
</template>
<script>
import _ from 'lodash';
import api from '@/api/api';
import { Field, ErrorMessage, FieldArray } from 'vee-validate';
import { DatePicker } from 'v-calendar';

export default {
  props: {
    fieldName: String,
    formLabel: String,
    minDate: {
      type: String,
      default: null,
    },
    maxDate: {
      type: String,
      default: null,
    },
    choice: {
      type: Number,
      default: 1,
    },
    fetchEventBySiteId: {
      type: Number,
    },
    fetchArea: {
      type: String,
      default: '',
    },
    fetchSessionType: {
      type: String,
      default: '',
    },
    disabledDates: {
      type: Array,
      default: () => [],
    },
    daterangeMarker: {
      type: Object,
      default: null,
    },
    selfDateArray: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Field,
    ErrorMessage,
    FieldArray,
    DatePicker,
  },
  data() {
    return {
      popover: {
        keepVisibleOnInput: true,
        visibility: 'focus',
      },
      masks: {
        title: 'YYYY MMMM',
        input: 'YYYY-MM-DD',
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DDTHH:mm:ss',
        timeAdjust: '00:00:00',
      },
      systemEvents: [],
      systemDisabledDates: [],
      timeOptions: {},
    };
  },
  methods: {
    addCallback(pushFunc) {
      if (this.isRangeSetted) pushFunc({ choice: this.choice });
    },
    async pageChange({ month, year }) {
      const thisDate = this.$moment({ year, month: month - 1 });

      this.fetchSystemEvents({
        startDate: thisDate.startOf('month').format('YYYY-MM-DD'),
        endDate: thisDate.endOf('month').format('YYYY-MM-DD'),
      });
    },
    async fetchSystemEvents(config) {
      const events = await api.getDatepickerEvents({
        startDate: config.startDate,
        endDate: config.endDate,
        siteId: this.fetchEventBySiteId,
        rentRange: this.fetchArea,
        activitySessionType: this.fetchSessionType,
      });
      this.systemEvents = _.chain(events)
        .filter(['isOpen', false])
        .groupBy('title')
        .mapValues((dates) => _.map(dates, 'startDate'))
        .omit('')
        .value();
      this.systemDisabledDates = _.chain(events).filter(['isOpen', false]).map('startDate').value();
    },
  },
  computed: {
    isRangeSetted() {
      return this.minDate && this.maxDate;
    },
    attrs() {
      return [...this.dotDates, ...this.dateRangeDates];
    },
    // 顯示總檔期 + 展演日範圍
    dateRangeDates() {
      return [
        {
          highlight: {
            color: 'gray',
            fillMode: 'light',
          },
          dates: {
            start: this.daterangeMarker.start,
            end: this.daterangeMarker.end,
          },
        },
        {
          highlight: {
            fillMode: 'light',
          },
          dates: {
            start: this.daterangeMarker.showStart,
            end: this.daterangeMarker.showEnd,
          },
        },
      ];
    },
    // systemEvents
    dotDates() {
      return _.map(this.systemEvents, (val, key) => ({
        dot: 'orange',
        dates: val,
        order: 1,
        popover: {
          label: key,
        },
      }));
    },
    totalDisabledDates() {
      return [...this.disabledDates, ...this.systemDisabledDates];
    },
  },
  watch: {
    selfDateArray: {
      deep: true,
      immediate: true,
      handler(dates) {
        if (dates.length <= 0) return;
        const { timeOptions } = this;
        dates.forEach(async ({ entryExitDate }) => {
          if (!entryExitDate) return;
          const date = this.$moment(entryExitDate).format('YYYY-MM-DD');
          timeOptions[date] = await api.getConcertTimeSelectOption(date);
        });
      },
    },
  },
};
</script>
