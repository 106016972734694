<template>
  <div>
    <FormWizard
      :form-steps-name="steps"
      :schema-array="schemas"
      :init-form="initForm"
      :init-step="initStep"
      :has-save-draft="true"
      :submit-step-index="4"
      finish-text="回申請案件總表"
      @submit-form="submit"
      @save-draft="saveDraft"
      @finish="finish"
    >
      <template v-slot="{ currentFormData, setValues }">
        <FormWizardStep :step="0">
          <div class="panel">
            <ul class="dotList">
              <li>
                在您填寫申請表之前，請先確認是否備妥以下資料，以利填寫表單：
                <ul>
                  <li>― 活動企劃書(含節目內容、日程規劃概要/演出者)</li>
                  <li>― 申請單位簡介(含相關實績)</li>
                  <li>― 演出者意向書或節目方授權文件</li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="form-item">
            <Field v-slot="{ handleChange, value, errors }" name="acceptTerm" type="checkbox">
              <div class="form-checkbox" :class="{ 'is-invalid': errors.length > 0 }">
                <label for="acceptTerm">
                  <input
                    id="acceptTerm"
                    type="checkbox"
                    name="acceptTerm"
                    @change="handleChange(!value)"
                    :checked="value"
                  />
                  <span class="form-checkbox-box"></span>
                  我已詳閱並同意上述注意事項
                </label>
              </div>
            </Field>
            <div class="form-promp is-invalid">
              <ErrorMessage name="acceptTerm" />
            </div>
          </div>
        </FormWizardStep>

        <FormWizardStep :step="1">
          <div class="grid-row">
            <div class="col-12_md-6">
              <div class="form-item">
                <label for="" class="form-label">會員姓名</label>
                <input type="text" class="form-input" readonly :value="userName" />
              </div>
            </div>
            <div class="col-12_md-6"></div>
            <div class="col-12_md-6">
              <FormInput field-name="activityName" field-label="活動名稱" />
            </div>
            <div class="col-12_md-6">
              <FormInput
                field-name="activityNameEN"
                field-label="活動英文名稱"
                :is-required="false"
              />
            </div>
            <div class="col-12_md-6">
              <FormRadio
                field-label="檔期屬性"
                field-name="activitySessionType"
                :options="formOption.activitySessionType"
              />
            </div>
            <div class="col-12_md-6">
              <FormRadio
                field-name="activityType"
                field-label="活動型式"
                :options="formOption.activityType"
              />
            </div>
            <div class="col-12_md-6">
              <FormRadio
                field-label="租用範圍"
                field-name="activityArea"
                :options="formOption.activityArea"
              />
            </div>
            <div class="col-12_md-6">
              <FormRadio
                field-label="售票"
                field-name="activityCharge"
                :options="formOption.activityCharge"
              />
            </div>
          </div>

          <hr class="form-hr" />

          <div class="form-title">選擇檔期時段</div>
          <div class="form-promp is-notify">申請檔期請包含活動進撤場且日期必須相連</div>
          <div class="grid-row mb-5">
            <div class="col-12_md-6">
              <DateRange
                form-label="第一意願檔期時段"
                field-start-name="totalStartDate1"
                field-end-name="totalEndDate1"
                :min-date="$moment().format('YYYY-MM-DD')"
                :fetch-event-by-site-id="1"
                :fetch-area="currentFormData.activityArea"
                :fetch-session-type="currentFormData.activitySessionType"
                @range-change="setValues({ showStartDate1: null, showEndDate1: null })"
              />
            </div>
            <div class="col-12_md-6">
              <DateRange
                form-label="第一意願演出時段"
                field-start-name="showStartDate1"
                field-end-name="showEndDate1"
                :min-date="currentFormData.totalStartDate1"
                :max-date="currentFormData.totalEndDate1"
                :fetch-event-by-site-id="1"
                :fetch-area="currentFormData.activityArea"
                :fetch-session-type="currentFormData.activitySessionType"
                @range-change="setValues({ sessionList1: [], entryList1: [], exitsList1: [] })"
              />
            </div>
            <div class="col-12 mb-1">
              <DateArray
                field-name="sessionList1"
                :choice="1"
                :fetch-event-by-site-id="1"
                :fetch-area="currentFormData.activityArea"
                :min-date="currentFormData.showStartDate1"
                :max-date="currentFormData.showEndDate1"
                :daterange-marker="{
                  start: currentFormData.totalStartDate1,
                  end: currentFormData.totalEndDate1,
                  showStart: currentFormData.showStartDate1,
                  showEnd: currentFormData.showEndDate1,
                }"
              />
            </div>
            <div class="col-12_md-6 mb-1">
              <DateArray2
                form-label="進場日"
                field-name="entryList1"
                :choice="1"
                :fetch-event-by-site-id="1"
                :fetch-area="currentFormData.activityArea"
                :self-date-array="currentFormData.entryList1"
                :min-date="currentFormData.totalStartDate1"
                :max-date="dateOffset(currentFormData.showStartDate1, -1)"
                :daterange-marker="{
                  start: currentFormData.totalStartDate1,
                  end: currentFormData.totalEndDate1,
                  showStart: currentFormData.showStartDate1,
                  showEnd: currentFormData.showEndDate1,
                }"
              />
            </div>
            <div class="col-12_md-6 mb-1">
              <DateArray2
                form-label="撤場日"
                field-name="exitsList1"
                :choice="1"
                :fetch-event-by-site-id="1"
                :fetch-area="currentFormData.activityArea"
                :self-date-array="currentFormData.exitsList1"
                :min-date="dateOffset(currentFormData.showEndDate1, 1)"
                :max-date="currentFormData.totalEndDate1"
                :daterange-marker="{
                  start: currentFormData.totalStartDate1,
                  end: currentFormData.totalEndDate1,
                  showStart: currentFormData.showStartDate1,
                  showEnd: currentFormData.showEndDate1,
                }"
              />
            </div>
          </div>
          <div class="grid-row">
            <div class="col-12_md-6">
              <DateRange
                form-label="第二意願檔期時段"
                field-start-name="totalStartDate2"
                field-end-name="totalEndDate2"
                :min-date="$moment().format('YYYY-MM-DD')"
                :fetch-event-by-site-id="1"
                :fetch-area="currentFormData.activityArea"
                :fetch-session-type="currentFormData.activitySessionType"
                :is-required="false"
                @range-change="setValues({ showStartDate2: null, showEndDate2: null })"
              />
            </div>
            <div class="col-12_md-6">
              <DateRange
                form-label="第二意願演出時段"
                field-start-name="showStartDate2"
                field-end-name="showEndDate2"
                :min-date="currentFormData.totalStartDate2"
                :max-date="currentFormData.totalEndDate2"
                :fetch-event-by-site-id="1"
                :fetch-area="currentFormData.activityArea"
                :fetch-session-type="currentFormData.activitySessionType"
                :is-required="false"
                @range-change="setValues({ sessionList2: [], entryList2: [], exitsList2: [] })"
              />
            </div>
            <div class="col-12 mb-1">
              <DateArray
                field-name="sessionList2"
                :choice="2"
                :fetch-event-by-site-id="1"
                :fetch-area="currentFormData.activityArea"
                :min-date="currentFormData.showStartDate2"
                :max-date="currentFormData.showEndDate2"
                :daterange-marker="{
                  start: currentFormData.totalStartDate2,
                  end: currentFormData.totalEndDate2,
                  showStart: currentFormData.showStartDate2,
                  showEnd: currentFormData.showEndDate2,
                }"
              />
            </div>
            <div class="col-12_md-6 mb-1">
              <DateArray2
                form-label="進場日"
                field-name="entryList2"
                :choice="2"
                :fetch-event-by-site-id="1"
                :fetch-area="currentFormData.activityArea"
                :self-date-array="currentFormData.entryList2"
                :min-date="currentFormData.totalStartDate2"
                :max-date="dateOffset(currentFormData.showStartDate2, -1)"
                :daterange-marker="{
                  start: currentFormData.totalStartDate2,
                  end: currentFormData.totalEndDate2,
                  showStart: currentFormData.showStartDate2,
                  showEnd: currentFormData.showEndDate2,
                }"
              />
            </div>
            <div class="col-12_md-6 mb-1">
              <DateArray2
                form-label="撤場日"
                field-name="exitsList2"
                :choice="2"
                :fetch-event-by-site-id="1"
                :fetch-area="currentFormData.activityArea"
                :self-date-array="currentFormData.exitsList2"
                :min-date="dateOffset(currentFormData.showEndDate2, 1)"
                :max-date="currentFormData.totalEndDate2"
                :daterange-marker="{
                  start: currentFormData.totalStartDate2,
                  end: currentFormData.totalEndDate2,
                  showStart: currentFormData.showStartDate2,
                  showEnd: currentFormData.showEndDate2,
                }"
              />
            </div>
          </div>

        </FormWizardStep>

        <FormWizardStep :step="2">
          <div class="form-promp is-notify">限PDF格式，各檔案不得超過20MB</div>
          <div class="grid-row">
            <div class="col-12_md-4">
              <FileInput
                field-label="活動企劃書(含節目規範概要/演出者)"
                field-name="file1"
                :limit-size="20"
                :limit-type="['pdf']"
              />
            </div>
            <div class="col-12_md-4">
              <FileInput
                field-label="申請單位簡介(含相關實績)"
                field-name="file2"
                :limit-size="20"
                :limit-type="['pdf']"
              />
            </div>
            <div class="col-12_md-4">
              <FileInput
                field-label="演出者意向書或節目方授權文件"
                field-name="file3"
                :limit-size="20"
                :limit-type="['pdf']"
              />
            </div>
          </div>
        </FormWizardStep>

        <FormWizardStep :step="3">
          <div
            class="form-title"
            style="display: flex; justify-content: space-between; align-items: center"
          >
            主要聯絡人
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="setValues(userDataForForm)"
            >
              帶入會員資料
            </button>
          </div>
          <div class="grid-row">
            <div class="col-12_md-6">
              <FormInput field-name="contactName" field-label="姓名" />
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="contactEmail" field-label="Email" field-type="email" />
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="contactTel" field-label="電話" field-type="tel" />
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="contactMobile" field-label="手機" />
            </div>
            <div class="col-12">
              <FormInput field-label="聯絡地址" field-name="address" />
            </div>
          </div>

          <hr class="form-hr" />

          <div class="form-title">其他聯絡人</div>
          <div class="grid-row">
            <div class="col-12_md-6">
              <FormInput field-name="otherName" field-label="姓名" :is-required="false" />
            </div>
            <div class="col-12_md-6">
              <FormInput
                field-name="otherEmail"
                field-label="Email"
                field-type="email"
                :is-required="false"
              />
            </div>
            <div class="col-12_md-6">
              <FormInput
                field-name="otherTel"
                field-label="電話"
                field-type="tel"
                :is-required="false"
              />
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="otherMobile" field-label="手機" :is-required="false" />
            </div>
          </div>
        </FormWizardStep>

        <FormWizardStep :step="4">
          <div class="swipeTable-wrapper">
            <table class="table table-v-center table-center">
              <tbody>
                <tr>
                  <th class="fixed-th" width="20%">志願序</th>
                  <td width="40%">第一意願</td>
                  <td width="40%">第二意願</td>
                </tr>
                <tr>
                  <th class="fixed-th">檔期時段</th>
                  <td>
                    <template
                      v-if="currentFormData.totalStartDate1 && currentFormData.totalEndDate1"
                    >
                      {{ currentFormData.totalStartDate1 }} 至 {{ currentFormData.totalEndDate1 }}，
                      共{{
                        dateDuration(
                          currentFormData.totalStartDate1,
                          currentFormData.totalEndDate1
                        )
                      }}日
                    </template>
                    <template v-else>-</template>
                  </td>
                  <td>
                    <template
                      v-if="currentFormData.totalStartDate2 && currentFormData.totalEndDate2"
                    >
                      {{ currentFormData.totalStartDate2 }} 至 {{ currentFormData.totalEndDate2 }}，
                      共{{
                        dateDuration(
                          currentFormData.totalStartDate2,
                          currentFormData.totalEndDate2
                        )
                      }}日
                    </template>
                    <template v-else>-</template>
                  </td>
                </tr>
                <tr>
                  <th class="fixed-th">演出時段</th>
                  <td>
                    <template
                      v-if="currentFormData.showStartDate1 && currentFormData.showEndDate1"
                    >
                      {{ currentFormData.showStartDate1 }} 至 {{ currentFormData.showEndDate1 }}，
                      共{{
                        dateDuration(
                          currentFormData.showStartDate1,
                          currentFormData.showEndDate1
                        )
                      }}日
                    </template>
                    <template v-else>-</template>
                  </td>
                  <td>
                    <template
                      v-if="currentFormData.showStartDate2 && currentFormData.showEndDate2"
                    >
                      {{ currentFormData.showStartDate2 }} 至 {{ currentFormData.showEndDate2 }}，
                      共{{
                        dateDuration(
                          currentFormData.showStartDate2,
                          currentFormData.showEndDate2
                        )
                      }}日
                    </template>
                    <template v-else>-</template>
                  </td>
                </tr>
                <tr>
                  <th class="fixed-th">展演日</th>
                  <td>
                    <ul v-if="currentFormData.sessionList1 && currentFormData.sessionList1.length > 0">
                      <li v-for="{ showDate, sessions } in currentFormData.sessionList1" :key="showDate">
                        {{ dateParser(showDate) }}，{{ sessions }}場
                      </li>
                    </ul>
                    <template v-else>-</template>
                  </td>
                  <td>
                    <ul v-if="currentFormData.sessionList2 && currentFormData.sessionList2.length > 0">
                      <li v-for="{ showDate, sessions } in currentFormData.sessionList2" :key="showDate">
                        {{ dateParser(showDate) }}，{{ sessions }}場
                      </li>
                    </ul>
                    <template v-else>-</template>
                  </td>
                </tr>
                <tr>
                  <th class="fixed-th">進撤場日</th>
                  <td>
                    <ul v-if="currentFormData.entryList1.length > 0 && currentFormData.exitsList1.length > 0">
                      <li v-for="session in [...currentFormData.entryList1, ...currentFormData.exitsList1]" :key="session.entryExitDate" style="display: flex; justify-content: center;">
                        <div>{{ dateParser(session.entryExitDate) }}，</div>
                        <ul>
                          <li v-for="time in session.timeDuration" :key="time">{{ timeParser('1', time) }}</li>
                        </ul>
                      </li>
                    </ul>
                    <span v-else>-</span>
                  </td>
                  <td>
                    <ul v-if="currentFormData.entryList2.length > 0 && currentFormData.exitsList2.length > 0">
                      <li v-for="session in [...currentFormData.entryList2, ...currentFormData.exitsList2]" :key="session.entryExitDate" style="display: flex; justify-content: center;">
                        <div>{{ dateParser(session.entryExitDate) }}，</div>
                        <ul>
                          <li v-for="time in session.timeDuration" :key="time">{{ timeParser('2', time) }}</li>
                        </ul>
                      </li>
                    </ul>
                    <span v-else>-</span>
                  </td>
                </tr>
                <tr>
                  <th class="fixed-th">進撤場日場地租借費用</th>
                  <td>{{ rentEstimate[0].entryExitsFee }}元</td>
                  <td>{{ rentEstimate[1].entryExitsFee }}元</td>
                </tr>
                <tr>
                  <th class="fixed-th">展演日場地租借費用</th>
                  <td>{{ rentEstimate[0].showDayFee }}元</td>
                  <td>{{ rentEstimate[1].showDayFee }}元</td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="subtotal">
                  <th class="fixed-th">合計</th>
                  <td>{{ rentEstimate[0].totalFee }}元</td>
                  <td>{{ rentEstimate[1].totalFee }}元</td>
                </tr>
                <tr class="guarantee">
                  <th class="fixed-th">保證金</th>
                  <td>{{ rentEstimate[0].guaranteeFee }}元</td>
                  <td>{{ rentEstimate[1].guaranteeFee }}元</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="panel">此費用僅供參考，實際費用以簽約金額為準。</div>
        </FormWizardStep>

        <FormWizardStep :step="5">
          <div class="text-center pt-5 pb-5">
            <Vue3Lottie :animationData="FinishJSON" width="150px" :loop="false" />
            <div class="h3">謝謝您!您已經完成表演廳檔期申請!</div>
            <div class="h3">您的案件編號：{{ finishNumNo }}</div>
            <p>如欲追蹤案件申請進度，可至各申請單位的「申請案件總表」查詢</p>
          </div>
        </FormWizardStep>
      </template>
    </FormWizard>
  </div>
</template>
<script>

import { mapActions, mapState, mapWritableState } from 'pinia';
import { useUserData } from '@/store/user';
import { useFormStore } from '@/store/form';
import _ from 'lodash';
import api from '@/api/api';
import { concertRent as schemas } from '@/utils/schema';
import { Vue3Lottie } from 'vue3-lottie';
import FinishJSON from '@/assets/images/finish.json';
import { Field, ErrorMessage } from 'vee-validate';
import FormWizard from '@/components/FormWizard.vue';
import FormWizardStep from '@/components/FormWizardStep.vue';
import FormInput from '@/components/Input/FormInput.vue';
import FormRadio from '@/components/Input/FormRadio.vue';
import DateRange from '@/components/Input/DateRange.vue';
import DateArray from '@/components/Input/DateArray.vue';
import FileInput from '@/components/Input/FileInput.vue';
import DateArray2 from '@/components/Input/DateArray2.vue';

export default {
  components: {
    FormWizard,
    FormWizardStep,
    FormInput,
    FileInput,
    FormRadio,
    DateRange,
    DateArray,
    DateArray2,
    Field,
    ErrorMessage,
    Vue3Lottie,
  },
  data() {
    return {
      steps: ['申請須知', '案件資訊', '文件上傳', '聯絡人', '費用試算', '完成申請'],
      schemas,
      initStep: 0,
      initForm: {
        acceptTerm: false,
        activitySessionType: '1',
        activityType: '1',
        activityArea: '1',
        activityCharge: '1',
        entryList1: [],
        exitsList1: [],
        entryList2: [],
        exitsList2: [],
      },
      FinishJSON,
      finishNumNo: '',
      concertTimeOption: {},
    };
  },
  methods: {
    async submit(formData, nextCallback) {
      const { submitResult, resultMessage } = await this.submitEventForm();
      if (submitResult === null) {
        alert(resultMessage);
      } else {
        this.finishNumNo = submitResult.numNo;
        nextCallback();
      }
    },
    async saveDraft() {
      const { submitResult, resultMessage } = await this.submitEventForm(true);
      if (submitResult === null) {
        alert(resultMessage);
      } else {
        this.finish();
      }
    },
    finish() {
      this.$router.push('/user/project');
    },
    dateParser(dateString) {
      if (!dateString) return '-';
      return this.$moment(dateString).format('YYYY-MM-DD');
    },
    timeParser(optionChargeType, optionVal) {
      const { periodFeeList: options } = this.rentRateTable.find(({ chargeType }) => chargeType === optionChargeType);
      const periodData = options.find((({ periodId }) => periodId === optionVal));
      return periodData?.periodText || optionVal;
    },
    async updateTimeOption(date) {
      if (date) {
        const options = await api.getConcertTimeSelectOption(date);
        this.concertTimeOption[date] = options;
      }
    },
    ...mapActions(useFormStore, ['submitEventForm', 'resetForm']),
  },
  computed: {
    ...mapState(useUserData, ['userDataForForm', 'userName']),
    ...mapState(useFormStore, {
      formDataStore: 'formData',
      formOption: (store) => store.formOption.concert,
      rentEstimate: 'rentEstimate',
      rentRateTable: 'rentRateTable',
      isDraft: 'isDraft',
    }),
    ...mapWritableState(useFormStore, ['siteId']),
  },
  created() {
    this.siteId = 1;
    // 判斷是否要載入 store中的 formData
    if (this.isDraft) this.initForm = this.formDataStore;
  },
  unmounted() {
    this.resetForm();
  },
};
</script>
